import React from 'react'
import { css } from 'emotion'
import Layout from '../components/layout'

import Heading from '../components/small/heading'
import BtnLink from '../components/small/btn-link'
import ContactIcon from '../components/small/contact-icon'

import heroImg from '../images/hero.jpg'

const ContactDeets = () => {
  const cont = css`
    display: flex;
    flex-wrap: wrap;

    > a {
      margin-bottom: 20px;
      @media (max-width: 600px) {
        min-width: 180px;
      }
      @media (min-width: 601px) {
        margin-right: 20px;
      }
    }
  `
  return (
    <ul className={cont}>
      <ContactIcon type="email" text="true" />
      <ContactIcon type="skype" text="true" />
      <ContactIcon type="linkedin" text="true" />
      <ContactIcon type="phone" text="true" />
    </ul>
  )
}

const Hero = () => {
  const hero = css`
    height: 316px;
    background: url(${heroImg});
    margin-bottom: 20px;

    @media (max-width: 980px) {
      display: none;
    }
  `
  return <div className={hero} />
}

const SectionWrap = css`
  display: flex;
  flex-direction: column;

  @media (min-width: 960px) {
    flex-direction: row;
    justify-content: space-between;
  }
`

const Section = props => {
  const cont = css`
    margin-bottom: 30px;

    @media (min-width: 960px) {
      width: 30%;
    }
  `
  return <div className={cont}>{props.children}</div>
}

const IndexPage = () => {
  return (
    <Layout>
      <Hero />

      <div className={SectionWrap}>
        <Section>
          <Heading>Welcome</Heading>
          <p>
            Caroline Chetwood is a Chartered Accountant and Chartered Tax
            Adviser, specialising in the provision of tax and accounting
            services to individuals and small and medium sized business.
          </p>
          <p>
            <BtnLink to="/services">See all my services</BtnLink>
          </p>
        </Section>
        <Section>
          <Heading>Experience</Heading>
          <p>
            I have 25 years experience including over 10 years at top London
            firms. I aim to provide the best possible service without the fees
            of a larger firm. I run my practice round my clients' needs. I will
            try and provide evening and weekend appointments when required and
            respond to you promptly.
          </p>
          <p>
            <BtnLink to="/experience">More about my experience</BtnLink>
          </p>
        </Section>
        <Section>
          <Heading>Get in touch</Heading>
          <p>
            If you have any questions or would like to book a free initial
            consultation, get in touch via the following:
          </p>
          <ContactDeets />
        </Section>
      </div>
    </Layout>
  )
}

export default IndexPage
